import {
  extendTheme, Accordion, AccordionButton, AccordionItem,
  AccordionPanel, Badge, Box, Button, ChakraProvider, Container,
  Flex, Icon, Image, Input, Stack, Text, useMediaQuery,
  Drawer, DrawerContent, DrawerBody, DrawerHeader, DrawerCloseButton,
  Heading, ModalOverlay, Modal, ModalContent, ModalHeader, ModalCloseButton,
  ModalBody, FormControl, FormLabel,
  ToastProvider,
  useToast
} from '@chakra-ui/react';
import { RxArrowRight, RxEnvelopeClosed, RxInfoCircled } from "react-icons/rx";
import { motion, useInView } from 'framer-motion';
import { FaArrowDownLong, FaFacebook, FaTwitter, FaInstagram, FaLinkedin, FaCircleMinus, FaCirclePlus } from "react-icons/fa6";
import { FcApproval, FcMenu, } from "react-icons/fc";
import { Fragment, useEffect, useRef, useState } from 'react';
import faqs from './data/faqs.json';
import { RiSettings2Fill } from 'react-icons/ri';

const featureList = [
  {
    label: 'Buy',
    background: {
      xAxis: '90%',
      yAxis: '10%'
    },
    image: '/images/features-image-1.png',
    content: 'Get more value for your car faster, easier and more securely',
    card: {
      image: {
        mobile: '/images/buy-widget-mobile.svg',
        desktop: '/images/buy-widget.svg'
      },
      posX: '10px',
      posY: '47%'
    }
  },
  {
    label: 'Sell',
    background: {
      xAxis: '50%',
      yAxis: '10%'
    },
    image: '/images/features-image-2.png',
    content: 'Get more value for your car faster, easier and more securely.',
    card: {
      image: {
        mobile: '/images/sale-widget-mobile.svg',
        desktop: '/images/sell-widget.svg'
      },
      posX: '10%',
      posY: '15px'
    }
  },
  {
    label: 'Rent',
    background: {
      xAxis: '70%',
      yAxis: '10%'
    },
    image: '/images/features-image-3.png',
    content: 'Choose from a premium fleet of rental cars. Whether for business or leisure, Motaa has the car you need.',
    card: {
      image: {
        mobile: '/images/rent-not-mobile.svg',
        desktop: '/images/rent-not.svg'
      },
      posX: '10%',
      posY: '55.0%'
    }
  },
]

const partnerImages = [
  '/images/black-businessman.png',
  '/images/mechanic-fixing-tyre.png',
  '/images/car-sale.png',
  '/images/workshop.png',
]

const theme = extendTheme({
  colors: {
    'primary': '#0065B5',
    'secondary': '#1C3D5A',
    'tertiary': '#FDD153',
    'accent': '#F2F3F5',
    'white': '#FFFFFF',
  }
})


const animations = {
  fadeInUp: {
    initial: { opacity: 0, y: 50 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 2.5, delay: 1.5 }, // Add 1.5 seconds delay
  },
  scaleOnHover: {
    whileHover: { scale: 1.05 },
    transition: { duration: 0.2 },
  },
  fadeInLeft: {
    initial: { opacity: 0, x: -50 },
    animate: { opacity: 1, x: 0 },
    transition: { duration: 0.5, delay: 1.5 }, // Add 1.5 seconds delay
  },
  fadeInRight: {
    initial: { opacity: 0, x: 50 },
    animate: { opacity: 1, x: 0 },
    transition: { duration: 1.5 }, // Adjust duration here
  },
  fadeInPop: {
    initial: { opacity: 0, scale: 0.8 }, // Start with 0 opacity and scale down
    animate: { opacity: 1, scale: 1 }, // End with full opacity and normal scale
    transition: { duration: 1.6, delay: 0.5, ease: "easeOut" }, // Smooth transition
  },
};

const arrowDownAnimation = {
  initial: { y: 0 },
  animate: {
    y: [0, 10, 0], // Moves down and back up
    transition: {
      duration: 1, // Duration of one complete cycle
      ease: "easeInOut", // Easing function for a smooth animation
      repeat: Infinity, // Repeat indefinitely
      repeatType: "loop", // Loop the animation
    },
  },
};




function App() {
  const counterRef = useRef();
  // hero ref
  const heroRef = useRef(null);
  const heroView = useInView(heroRef, { once: true });
  // about-us-ref
  const aboutRef = useRef(null);
  const aboutView = useInView(aboutRef, { once: true });
  // mechanic-ref
  const mechRef = useRef(null);
  const mechView = useInView(mechRef, { once: true });
  // partner-ref
  const partnerRef = useRef(null);
  const partnerView = useInView(partnerRef, { once: true });
  // waitlist-ref
  const waitlistRef = useRef(null);
  const waitlistView = useInView(waitlistRef, { once: true });
 
  const [modalIsVisible, setModalVisibility] = useState(false)
  const [navIsOpen, setNavVisibility] = useState(false)
  const [counter, setCounter] = useState(null)
  const launchDate = new Date("2024-12-31T23:59:59").getTime();
  const toast = useToast({})

  function hideModal(){
    setModalVisibility(false)
  }

  function showModal(){
    setModalVisibility(true)
  }

  function hideNav(){
    setNavVisibility(false)
  }

  function showNav(){
    setNavVisibility(true)
  }

  function notify({ text, title, icon, level }){
    toast({
      description: text,
      title: title,
      icon: icon || <RxInfoCircled />,
      colorScheme: level || 'red',
      position: 'bottom-right',
      duration: 3500
    })
  }


  function updateCountdown() {
    try{

      const now = new Date().getTime();
      const timeLeft = launchDate - now;
  
      if (timeLeft < 0) {
          counterRef.current.innerHTML = "Countdown finished!";
          return;
      }
  
      const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
  
      counterRef.current.innerHTML = `
        <div class="d-flex justify-between text-center">
          <span class="subtitle timer"> ${days} : <br /> <span class="smalltext"> Days </span> </span>  
          <span class="subtitle timer"> ${hours} : <br /> <span class="smalltext"> Hours </span> </span>
          <span class="subtitle timer"> ${minutes} : <br /> <span class="smalltext"> Minutes </span> </span>
          <span class="subtitle timer"> ${seconds} <br /> <span class="smalltext"> Seconds </span></span>
        </Flex>
      `;
    }catch(err){}
  }

  async function handleWaitlistSubmission(data){
    // do something with the data
    try{
      const res = await fetch('https://flcdm7scyh.execute-api.eu-west-2.amazonaws.com/dev/submit', {
        method: 'post',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          "Name": data.name,
          "Email": data.email,
          "phoneNumber": data.phone_number
        }),
        mode: 'no-cors',
      })
  
      console.log("Response:", res)
      notify({
        text: "We'll send you an email when we launch",
        title: "Thanks for signing up!",
        level: 'whatsapp'
      })
    }catch(err){
      console.log("Error sending data:", err)
      notify({
        text: err.message,
        title: "Oops! Something's not right",
        level: 'red',
      })
    }
  }

  useEffect(() => {
    updateCountdown()
    const count = setInterval(() => updateCountdown(), 1000)
    setCounter(count)
  
    return () => {
      clearInterval(counter)
    }
  // eslint-disable-next-line
  }, [])


  window.onscroll = (ev) => {
    if(window.scrollY > 1000){
      document.getElementById('navbar').classList.add('scrolled');
    }else{
      document.getElementById('navbar').classList.remove('scrolled');
    }
  }

  const [isMobile] = useMediaQuery('(max-width: 768px)')

  return (
    <ChakraProvider theme={theme}>
      <ToastProvider />
      <div className="App">
        {/* Header Section */}
        <motion.section id='welcome' ref={heroRef}>
          <Box className='header' position={'relative'}
            as={motion.div}
            initial={{ opacity: 0, }}
            animate={{opacity: 1,}}
            transition={'1.5s forwards'}
            style={{backgroundImage: isMobile ? 'url("/images/hero-image-mobile.png")' : 'url("/images/hero-image.png")'}}
          >
            
            {/* Navbar */}
            <Box as={motion.div} flex={1} w={'100%'} animate={{ opacity: 1, }} initial={{ opacity: 0.6 }} transition={'.5s linear'} className='navbar' zIndex={'2000'} id='navbar'>
              <Flex className='navbar-inner'
                alignItems={'center'}
                px={6}
                py={4}
                justifyContent={'space-between'}
                flex={1} w={'100%'}
              >
                <Box as={Flex} alignItems={'center'} justifyContent={'center'} width={'80px'} height={'50px'} className='navbar-brand'>
                  <Image src='/images/motaa-trans_var_1.png' width={'100%'} className='navbar-brand' />
                </Box>

                {isMobile ?
                  <Fragment>
                    <Button onClick={showNav} colorScheme='transparent'>
                      <Icon sx={{ fill: 'white', '& *': {fill: 'white'}}} className='icon'><FcMenu /></Icon>
                    </Button>
                    <Drawer isOpen={navIsOpen} onClose={hideNav} placement='right'>
                      <DrawerContent>
                        <DrawerHeader>
                          <DrawerCloseButton />
                        </DrawerHeader>

                        <DrawerBody>
                          <Stack>
                            <Text onClick={hideNav} py={1} px={4} my={2} as={motion.a} href={"#welcome"} >Home</Text>
                            <Text onClick={hideNav} py={1} px={4} my={2} as={motion.a} href={"#what-we-offer"} >About</Text>
                            <Text onClick={hideNav} py={1} px={4} my={2} as={motion.a} href={"#find-mechanics"} >Features</Text>
                            <Text onClick={hideNav} py={1} px={4} my={2} as={motion.a} href={"#partner-with-us"} >For Businesses</Text>
                          </Stack>
                        </DrawerBody>
                      </DrawerContent>
                    </Drawer>
                  </Fragment>
                :
                  <Flex flex={3/4} flexWrap={'wrap'} className='navbar-nav' gap={8}>
                    <Text as={motion.a} href={"#welcome"} >Home</Text>
                    <Text as={motion.a} href={"#what-we-offer"} >About</Text>
                    <Text as={motion.a} href={"#find-mechanics"} >Features</Text>
                    <Text as={motion.a} href={"#partner-with-us"} >For Businesses</Text>
                  </Flex>
                }

              </Flex>
            </Box>

            {/* Hero */}
            <Box position={'relative'} className='hero' width={{ base: '100%', md: '70%', lg: '60%'}}>
              <Text as={motion.p}
               lineHeight={1} mb={3}
               className='hero-title pt-sans-regular animated'
               animate={heroView ? animations.fadeInUp.animate : animations.fadeInUp.initial}
               initial={animations.fadeInUp.initial}
              > Welcome to <br /> Nigeria's Largest <br /> Car Marketplace<Text className='box-dot' as={motion.span} color={'primary'}>.</Text> </Text>
              <Text as={motion.p}
               className='text animated'
               animate={heroView ? animations.fadeInUp.animate : animations.fadeInUp.initial}
               initial={animations.fadeInUp.initial}
              > Buy, sell, rent and find trusted mechanics all with ease and confidence of verified dealers. </Text>

              <Flex mt={'40px'} gap={4} flexWrap={'wrap'} justifyContent={'flex-start'}>
                <Button
                 as={motion.a} href='/#join-waitlist'
                 colorScheme='blue'
                 bg={'primary'}
                 className='animated'
                 animate={heroView ? animations.fadeInUp.animate : animations.fadeInUp.initial}
                 initial={animations.fadeInUp.initial}
                > Get early access </Button>
                <Button
                 as={motion.a} href='/#join-waitlist'
                 colorScheme='transparent'
                 className='btn-outline animated'
                 gap={2} alignItems={'center'}
                 animate={heroView ? animations.fadeInUp.animate : animations.fadeInUp.initial}
                 initial={animations.fadeInUp.initial}
                > See how it works <RxArrowRight className='icon' /> </Button>
              </Flex>
            </Box>

            <Box
              bg={'#fff'}
              borderRadius={'200px'}
              width={'50px'}
              height={'50px'}
              position={'absolute'}
              bottom={'-25px'}
              left={'calc(50% - 25px)'}
              alignContent={'center'}
              display={'flex'}
              p={2}
              justifyContent={'center'}
              placeContent={'center'}
              as={motion.div}
              >
              <motion.div
                initial="initial"
                animate="animate"
                variants={arrowDownAnimation}
              >
              <Icon as={FaArrowDownLong} className="icon" fill={'primary'} sx={{ '& > svg:first-child': {fill: 'primary'}}} />
              </motion.div>
            </Box>
          </Box>
        </motion.section>

        {/* Features Section */}
        <motion.section id='what-we-offer'>
          <Box py={'4rem'}>
            <Container maxW={'950px'} mb={'4rem'}>
              <Text lineHeight={1} textAlign={'center'} className='title'>Connect with verified car
              dealerships nationwide <Icon as={FcApproval} className="icon" fill={'primary'} sx={{'& > polygon:first-child': {fill: 'primary'}}} /> </Text>
              <Text mt={5} maxW={'500px'} mx={'auto'} lineHeight={1} textAlign={'center'} className='text'>Whether you're looking to purchase, sell, or rent a car, Motaa 
                connects you with verified dealers and car dealers and car rentals you can trust.
              </Text>
            </Container>

            <Flex
              justifyContent={{base: 'center'}}
              gap={4} flexWrap={'wrap'} px={4}
              ref={aboutRef}
            >
              {
                featureList.map((feature, idx) =>
                  <Box key={idx} as={motion.div} className='feature-card animated' sx={{
                    position: 'relative',
                    display: 'block',
                    backgroundColor: 'rgba(0, 0, 0, 0.27)',
                    backgroundImage: `url("${feature.image}")`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundBlendMode: 'overlay',
                    borderRadius: '20px',
                    px: 3, py: 3,
                    backgroundPositionX: feature.background.xAxis,
                  }}
                  initial={animations.fadeInLeft.initial}
                  animate={aboutView ? animations.fadeInLeft.animate : animations.fadeInLeft.initial}
                  whileHover={{ scale: 1.025 }}
                  // transition={'0.21s linear'}
                  // transitionDelay={`0.${idx}5s`}
                  >
                    <Image
                     src={isMobile ? feature.card.image.mobile : feature.card.image.desktop }
                     width={'250px'}
                     position={'absolute'}
                     top={feature.card.posY}
                     left={feature.card.posX}
                    />

                    <Box className=''
                      sx={{
                        position: 'relative',
                        top: 'calc(100% - 120px)',
                        color: '#fff',
                        fontSize: '15px',
                      }}
                    >
                      <Text px={3} py={1} borderRadius={'5px'} color={'#fff'} bg={'primary'} w={'max-content'}> {feature.label} </Text>
                      <Text mt={2}> {feature.content} </Text>
                    </Box>
                  </Box>
                )
              }

            </Flex>

          </Box>
        </motion.section>


        {/* Find Mechanics Section */}
        <motion.section id='find-mechanics'>
          <Flex flexWrap={'wrap'} py={10} px={5} alignItems={'flex-start'} justifyContent={'space-between'}>
            <Box px={{base: 2, md: 8}} width={{ base: '100%', md: '50%'}} py={{base: 7}}>
              <Text mb={5} className='title'>
                 Find Certified Mechanics in your area
                 <Text as={motion.span} color={'primary'}>.</Text>
                 <Icon className="icon">
                  <RiSettings2Fill fill='var(--primary)' />
                </Icon>
              </Text>
              <Text>Certified mechanice you can trust, ready to assist
                you with repairs, emergency response, and more all at
                affordable prices.
              </Text>

              <Stack mt={5}>
                <Flex gap={4} alignItems={'center'}>
                  <Icon color={'primary'} className='icon'>
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.83337 20.1666H20.1667" stroke="#0065B5" stroke-width="2.2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M11 5.5C6.44417 5.5 2.75 9.19417 2.75 13.75V20.1667H19.25V13.75C19.25 9.19417 15.5558 5.5 11 5.5Z" stroke="#0065B5" stroke-width="2.2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M11 1.83337V2.75004" stroke="#0065B5" stroke-width="2.2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M3.66663 3.66663L4.58329 4.58329" stroke="#0065B5" stroke-width="2.2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M18.3333 3.66663L17.4166 4.58329" stroke="#0065B5" stroke-width="2.2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </Icon>
                  <Text> Emergency Response </Text>
                </Flex>

                <Flex gap={4} alignItems={'center'}>
                  <Icon color={'primary'} className='icon'>
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M17.0021 21.5C18.1063 21.5 19.0014 20.6046 19.0014 19.5C19.0014 18.3954 18.1063 17.5 17.0021 17.5C15.898 17.5 15.0029 18.3954 15.0029 19.5C15.0029 20.6046 15.898 21.5 17.0021 21.5Z" stroke="#0065B5" stroke-width="2"/>
                      <path d="M7.00605 21.5C8.11019 21.5 9.00527 20.6046 9.00527 19.5C9.00527 18.3954 8.11019 17.5 7.00605 17.5C5.90192 17.5 5.00684 18.3954 5.00684 19.5C5.00684 20.6046 5.90192 21.5 7.00605 21.5Z" stroke="#0065B5" stroke-width="2"/>
                      <path d="M12.0039 12.5L6.00625 3.5M6.00625 3.5L8.00547 13.5M6.00625 3.5H3.83792C3.64888 3.5 3.48943 3.66098 3.46601 3.87547L3.13264 6.92857C3.75363 6.92857 4.25704 7.50421 4.25704 8.21429C4.25704 8.92437 3.75363 9.5 3.13264 9.5C2.64307 9.5 2.16217 9.14223 2.00781 8.64286M19.0012 19.5C21.6834 19.5 22 18.572 22 16.0125C22 14.7875 22 14.175 21.76 13.6578C21.5094 13.1178 21.0578 12.7814 20.108 12.0931C19.1647 11.4095 18.494 10.641 17.8549 9.58239C16.9435 8.07263 16.4878 7.31775 15.8043 6.90888C15.1209 6.5 14.3148 6.5 12.7026 6.5H12.0039V13.5" stroke="#0065B5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M5.00651 19.496C5.00651 19.496 3.84703 19.5063 3.48717 19.46C3.18729 19.34 2.82289 19.0585 2.58753 18.902C1.86781 18.4235 2.01175 18.62 2.01175 18.188C2.01175 17.5126 2.00768 14.5063 2.00768 14.5063V13.5463C2.00768 13.4863 1.946 13.4991 2.40752 13.5063H21.4801M9.00508 19.502H15.0027" stroke="#0065B5" stroke-width="2" stroke-linecap="round"/>
                    </svg>
                  </Icon>
                  <Text> Towing Service </Text>
                </Flex>

                <Flex gap={4} alignItems={'center'}>
                  <Icon color={'primary'} className='icon'>
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M2 22.5H13C17.9706 22.5 22 18.4706 22 13.5C22 8.52944 17.9706 4.5 13 4.5C8.36745 4.5 4.49744 8.00005 4 12.5" stroke="#0065B5" stroke-width="2" stroke-linecap="round"/>
                      <path d="M18.5 6L19.5 5M5.5 5L6.5 6" stroke="#0065B5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M16.5001 9.5L13.5608 12.4394M13.5608 12.4394C13.2893 12.1679 12.9143 12 12.5001 12C11.6717 12 11.0001 12.6716 11.0001 13.5C11.0001 14.3285 11.6717 15 12.5001 15C13.3285 15 14.0001 14.3285 14.0001 13.5C14.0001 13.0858 13.8322 12.7108 13.5608 12.4394Z" stroke="#0065B5" stroke-width="2" stroke-linecap="round"/>
                      <path d="M12.5 4V2.5" stroke="#0065B5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M10.5 2.5H14.5" stroke="#0065B5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M2 15.5H5" stroke="#0065B5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M2 19.5H7" stroke="#0065B5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </Icon>
                  <Text> 24/7 Availability </Text>
                </Flex>

                <Flex gap={4} alignItems={'center'}>
                  <Icon color={'primary'} className='icon'>
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M17 12.3045C17 11.9588 17 11.786 17.052 11.632C17.2032 11.1844 17.6018 11.0108 18.0011 10.8289C18.45 10.6244 18.6744 10.5222 18.8968 10.5042C19.1493 10.4838 19.4022 10.5382 19.618 10.6593C19.9041 10.8198 20.1036 11.1249 20.3079 11.373C21.2513 12.5188 21.7229 13.0918 21.8955 13.7236C22.0348 14.2334 22.0348 14.7666 21.8955 15.2764C21.6438 16.1979 20.8485 16.9704 20.2598 17.6854C19.9587 18.0511 19.8081 18.234 19.618 18.3407C19.4022 18.4618 19.1493 18.5162 18.8968 18.4958C18.6744 18.4778 18.45 18.3756 18.0011 18.1711C17.6018 17.9892 17.2032 17.8156 17.052 17.368C17 17.214 17 17.0412 17 16.6955V12.3045Z" stroke="#0065B5" stroke-width="2"/>
                      <path d="M7 12.3046C7 11.8694 6.98778 11.4782 6.63591 11.1722C6.50793 11.0609 6.33825 10.9836 5.99891 10.829C5.55001 10.6246 5.32556 10.5224 5.10316 10.5044C4.43591 10.4504 4.07692 10.9058 3.69213 11.3732C2.74875 12.519 2.27706 13.0919 2.10446 13.7237C1.96518 14.2336 1.96518 14.7668 2.10446 15.2766C2.3562 16.1981 3.15152 16.9705 3.74021 17.6856C4.11129 18.1363 4.46577 18.5475 5.10316 18.496C5.32556 18.478 5.55001 18.3757 5.99891 18.1713C6.33825 18.0167 6.50793 17.9394 6.63591 17.8281C6.98778 17.5221 7 17.131 7 16.6957V12.3046Z" stroke="#0065B5" stroke-width="2"/>
                      <path d="M20 11V9.5C20 5.63401 16.4183 2.5 12 2.5C7.58172 2.5 4 5.63401 4 9.5V11" stroke="#0065B5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M20 18C20 22.5 16 22.5 12 22.5" stroke="#0065B5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </Icon>
                  <Text> Exceptional Customer Service </Text>
                </Flex>

              </Stack>
            </Box>

            <Box
              width={{ base: '100%', md: '45%'}}
              ref={mechRef}
              >
              <Box
                as={motion.div}
                initial={animations.fadeInRight.initial}
                animate={mechView ? animations.fadeInRight.animate : animations.fadeInRight.initial}
                transition={animations.fadeInRight.transition}
                className='animated'
                sx={{
                  backgroundImage: `url('/images/mechanic-image.png')`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  width: '100%',
                  position: 'relative',
                  height: '300px',
                  borderRadius: '15px',
                  ml: 'auto',
                }}
              >
                <Image
                  width={{base: '250px', md: '300px'}}
                  src={isMobile ? "/images/mech-not-mobile.svg" : "/images/mech-not.svg"}
                  position={'absolute'}
                  top={'15px'}
                  right={'15px'}
                />

                <Image
                  width={'300px'}
                  src={isMobile ? "/images/mech-widget-mobile.svg" : "/images/mech-widget.svg"}
                  position={'absolute'}
                  bottom={'15px'}
                  left={{base: '0px', md:'-18.5%'}}
                />
              </Box>
            </Box>
          </Flex>
        </motion.section>


        {/* Become a Partner Section */}
        <motion.section id='partner-with-us'>
          <Flex flexWrap={'wrap'} py={10} px={5} alignItems={'flex-start'} justifyContent={'space-between'}>
            <Box width={{ base: '100%', md: '50%'}} px={{ base: 0, md: 8}} py={{base: 7}}  ref={partnerRef}>
              <Flex mt={4} justifyContent={'flex-start'} gap={4}>
                {
                  partnerImages.slice(0, 2).map((img, idx) =>
                    <Box key={idx} as={motion.div}
                      sx={{
                        backgroundImage: `url(${img})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPositionY: '35%',
                        width: '100%',
                        maxW: "150px",
                        height: '150px',
                        borderRadius: '15px',
                      }}
                      className='animated'
                      initial={animations.fadeInPop.initial}
                      animate={partnerView ? animations.fadeInPop.animate : animations.fadeInPop.initial}
                    >
                    </Box>
                )}
              </Flex>

              <Flex mt={4} justifyContent={{base: 'flex-end', xl: 'flex-start'}} gap={4}  mr={{ md: '-3rem', lg: '3rem'}} ml={{ xl: '3rem'}}>
                {
                  partnerImages.slice(2, 4).map((img, idx) =>
                    <Box key={idx} as={motion.div}
                      className='animated'
                      sx={{
                        backgroundImage: `url(${img})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        width: '100%',
                        maxW: "150px",
                        height: '150px',
                        borderRadius: '15px',
                      }}
                      initial={animations.fadeInPop.initial}
                      animate={partnerView ? animations.fadeInPop.animate : animations.fadeInPop.initial}
                    >
                    </Box>
                )}
              </Flex>
            </Box>
            
            <Box width={{ base: '100%', md: '50%'}} mr={{ base: 0, md: 'auto'}}>
              <Box px={{md: 8}} width={{ md: '97%' }} ml={{md: 'auto'}}>
                <Text mb={5} className='title'> Become a partner<Text as={motion.span} color={'tertiary'}>.</Text></Text>
                <Text>Whether you're a dealership, car rental or a repair shop,
                  Motaa gives you an opportunity to scale your business.
                </Text>

                <Stack mt={5}>
                  <Flex alignItems={'center'} gap={4}>
                    <Icon color={'primary'} className="icon">
                      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.5 14.25C9.5 15.22 10.25 16 11.17 16H13.05C13.85 16 14.5 15.32 14.5 14.47C14.5 13.56 14.1 13.23 13.51 13.02L10.5 11.97C9.91 11.76 9.51001 11.44 9.51001 10.52C9.51001 9.67999 10.16 8.98999 10.96 8.98999H12.84C13.76 8.98999 14.51 9.76999 14.51 10.74" stroke="#0065B5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12 8V17" stroke="#0065B5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M22 12.5C22 18.02 17.52 22.5 12 22.5C6.48 22.5 2 18.02 2 12.5C2 6.98 6.48 2.5 12 2.5" stroke="#0065B5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M17 3.5V7.5H21" stroke="#0065B5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M22 2.5L17 7.5" stroke="#0065B5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </Icon>
                    <Text my={2}> Increased Earnings </Text> 
                  </Flex>

                  <Flex alignItems={'center'} gap={4}>
                    <Icon color={'primary'} className="icon">
                      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 2.5V19.5C2 21.16 3.34 22.5 5 22.5H22" stroke="#0065B5" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M5 17.5L9.59 12.14C10.35 11.26 11.7 11.2 12.52 12.03L13.47 12.98C14.29 13.8 15.64 13.75 16.4 12.87L21 7.5" stroke="#0065B5" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </Icon>
                    <Text my={2}> Wider Visibility </Text> 
                  </Flex>

                  <Flex alignItems={'center'} gap={4}>
                    <Icon color={'primary'} className="icon">
                      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.0001 7.66C17.9401 7.65 17.8701 7.65 17.8101 7.66C16.4301 7.61 15.3301 6.48 15.3301 5.08C15.3301 3.65 16.4801 2.5 17.9101 2.5C19.3401 2.5 20.4901 3.66 20.4901 5.08C20.4801 6.48 19.3801 7.61 18.0001 7.66Z" stroke="#0065B5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M16.9702 14.9399C18.3402 15.1699 19.8502 14.9299 20.9102 14.2199C22.3202 13.2799 22.3202 11.7399 20.9102 10.7999C19.8402 10.0899 18.3102 9.84991 16.9402 10.0899" stroke="#0065B5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M5.96998 7.66C6.02998 7.65 6.09998 7.65 6.15998 7.66C7.53998 7.61 8.63998 6.48 8.63998 5.08C8.63998 3.65 7.48998 2.5 6.05998 2.5C4.62998 2.5 3.47998 3.66 3.47998 5.08C3.48998 6.48 4.58998 7.61 5.96998 7.66Z" stroke="#0065B5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M6.99994 14.9399C5.62994 15.1699 4.11994 14.9299 3.05994 14.2199C1.64994 13.2799 1.64994 11.7399 3.05994 10.7999C4.12994 10.0899 5.65994 9.84991 7.02994 10.0899" stroke="#0065B5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12.0001 15.13C11.9401 15.12 11.8701 15.12 11.8101 15.13C10.4301 15.08 9.33008 13.95 9.33008 12.55C9.33008 11.12 10.4801 9.96997 11.9101 9.96997C13.3401 9.96997 14.4901 11.13 14.4901 12.55C14.4801 13.95 13.3801 15.09 12.0001 15.13Z" stroke="#0065B5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M9.08997 18.2799C7.67997 19.2199 7.67997 20.7599 9.08997 21.6999C10.69 22.7699 13.31 22.7699 14.91 21.6999C16.32 20.7599 16.32 19.2199 14.91 18.2799C13.32 17.2199 10.69 17.2199 9.08997 18.2799Z" stroke="#0065B5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </Icon>
                    <Text my={2}> Affiliate programmes </Text> 
                  </Flex>

                  <Flex alignItems={'center'} gap={4}>
                    <Icon color={'primary'} className="icon">
                      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.0101 19.0101L15.0601 14.0601" stroke="#0065B5" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M15.06 14.06L11.52 17.6C10.74 18.38 9.47 18.38 8.69 17.6L4.44999 13.36C3.66999 12.58 3.66999 11.31 4.44999 10.53L11.52 3.46C12.3 2.68 13.57 2.68 14.35 3.46L18.59 7.70002C19.37 8.48002 19.37 9.75001 18.59 10.53L15.06 14.06Z" stroke="#0065B5" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M2 21.5H8" stroke="#0065B5" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M6.56006 8.41992L13.6301 15.4899" stroke="#0065B5" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </Icon>
                    <Text my={2}> Car Autions <Badge p={2} size={'small'} textTransform={'none'} colorScheme='green' borderRadius={'30px'}> Coming soon! </Badge> </Text> 
                  </Flex>

                </Stack>

                <Flex flexWrap={'wrap'} gap={3} mt={5}>
                  <Button as={motion.a} href='/#join-waitlist' w={{base: '100%', sm: '50%', md: 'initial'}} bg={'primary'} colorScheme='blue'> Register </Button>
                  <Button as={motion.a} href='/#join-waitlist' w={{base: '100%', sm: '50%', md: 'initial'}} border={'1px solid'} borderColor={'primary'} color={'primary'} colorScheme={'transparent'}> Learn More </Button>
                </Flex>
              </Box>
            </Box>
          </Flex>
        </motion.section>
        
        {/* Join Waitlist */}
        <motion.section id='join-waitlist'>
          <Box py={'100px'} 
           style={{
            backgroundImage: 'url("/images/background_pattern.png")',
            backgroundPosition: '50% 5%',
            backgroundColor: 'var(--primary)',
            backgroundBlendMode: 'overlay',
          }} ref={waitlistRef}
          >
            <Container w={'100%'} maxW={'750px'} textAlign={'center'} color={'white'}>
              <Text as={motion.p}
                initial={animations.fadeInUp.initial}
                animate={waitlistView ? animations.fadeInUp.animate : animations.fadeInUp.initial}
                my={4} className="title animated"> What are you waiting for<Text as={motion.span} color={'tertiary'}>?</Text></Text>
              <Text as={motion.p}
                initial={animations.fadeInUp.initial}
                animate={waitlistView ? animations.fadeInUp.animate : animations.fadeInUp.initial}
                my={4} className=" animated"> Join the waitlist now and get access to exclusive deals, discounts,
                <br /> cashbacks and lots more. Hurry now while offer lasts<Text as={motion.span} color={'tertiary'}>!!!</Text>
              </Text>

              <Box as={motion.div} 
                initial={animations.fadeInUp.initial}
                animate={waitlistView ? animations.fadeInUp.animate : animations.fadeInUp.initial}
                maxW={{base: '220px', md: '270px'}}
                mx={'auto'}
                justifyContent={'center'}
                my={4}
                ref={counterRef}
                className='subtitle animated countdown'
                textAlign={'center'}
              ></Box>
              
              <Button as={motion.button}
                initial={animations.fadeInUp.initial}
                animate={waitlistView ? animations.fadeInUp.animate : animations.fadeInUp.initial}
                onClick={showModal}
                className="animated"
                my={4} px={8} bg={'tertiary'}
                color={'primary'}
              > Join Waitlist </Button>
            </Container>
          </Box>
        </motion.section>

        {/* Waitlist Moday */}
        {modalIsVisible && <WaitlistModal show={modalIsVisible} onClose={hideModal} onSubmit={handleWaitlistSubmission} /> }

        {/* FAQs */}
        <section id='faqs'>
          <Container maxW={{md: '75%'}} py={'100px'} textAlign={'center'}>
            <Text my={2} className='title'> Frequently Asked Questions </Text>
            <Text my={2} className='text'> Still not convinced? <a href={'/'} className='link'>Chat with our team here.</a> </Text>

            <Stack mt={10} maxW={{md: '500px'}} mx={'auto'}>
              <Accordion allowMultiple allowToggle border={'none'} textAlign={'left'}>
                {
                  faqs.map((faq, idx) => 
                      <AccordionItem borderRadius={5} my={4} border={'1px solid lavender'}>
                        {({ isExpanded }) => (
                          <Fragment key={idx}>
                          <AccordionButton as={Flex} wrap={'nowrap'} alignItems={'center'} justifyContent={'space-between'}>
                            <Text flex={1} className='smalltext' textAlign={'left'}> {faq?.question} </Text>
                            <Icon className='icon' fontSize={'20px'}>{isExpanded ? <FaCircleMinus /> : <FaCirclePlus /> }</Icon>
                          </AccordionButton>

                          <AccordionPanel px={3} py={3}>
                            <Text className='smalltext'>{faq?.answer}</Text>
                          </AccordionPanel>
                          </Fragment>
                        )}
                      </AccordionItem>
                )}
              </Accordion>
            </Stack>
          </Container>
        </section>
        
        <footer>
          <Box bg={'primary'}>

            {/* Newsletter */}
            <Container py={'50px'} textAlign={'center'}>
              <Text className='subtitle' mb={4} color={'#fff'}> Subscribe to our newsletter! </Text>
              <Text color={'#fff'} className='smalltext' mb={4}> Never miss a beat. Subscribe now and keep up to date
                  with the latest news, deals and discounts on <b>Motaa</b>.
              </Text>

              <Flex gap={3} flex={1} justifyContent={'center'}>
                <Flex px={2} bg={'#fff'} alignItems={'center'} borderRadius={'5px'} justifyContent={'flex-start'} columnGap={4}>
                  <Icon><RxEnvelopeClosed className='icon' /></Icon>
                  <Input px={0} flex={1} type='email' border={'none'} sx={{ border: 'none', outline: 'none !important', boxShadow: 'none !important'}} placeholder='Email address' />
                </Flex>

                <Button bg={'tertiary'} className="smalltext" color={'primary'} colorScheme='yellow'> Subscribe </Button>
              </Flex>
            </Container>
            <hr />
            
            <Box px={{base: 4, md: '4rem'}} py={4}>
              <Flex flexWrap={'wrap'} py={4} alignItems={'center'} gap={4} justifyContent={'space-between'}>
                <Box as={Flex} alignItems={'center'} justifyContent={'center'} width={'80px'} height={'50px'} className='navbar-brand'>
                  <Image src='/images/motaa-trans_var_2.png' width={'100%'} className='navbar-brand' />
                </Box>

                <Flex flexWrap={'wrap'} justifyContent={'space-between'} alignItems={'center'} gap={4}>
                  <Text color={'white'}> Contact Us <Text as={motion.a} color='tertiary' href='mailto:hello@motaa.net'>hello@motaa.net</Text></Text>
                  <Flex alignItems={'center'} justifyContent={'space-between'} gap={4}>
                    <Text color={'white'}> Follow Us </Text>
                    <Flex gap={2}>
                      <Icon alignItems={'center'} justifyContent={'center'} display={'flex'} px={1.5} py={1.35} bg={'tertiary'} borderRadius={'200px'} className='icon'><FaFacebook /></Icon>
                      <Icon alignItems={'center'} justifyContent={'center'} display={'flex'} px={1.5} py={1.35} bg={'tertiary'} borderRadius={'200px'} className='icon'><FaTwitter /></Icon>
                      <Icon alignItems={'center'} justifyContent={'center'} display={'flex'} px={1.5} py={1.35} bg={'tertiary'} borderRadius={'200px'} className='icon'><FaInstagram /></Icon>
                      <Icon alignItems={'center'} justifyContent={'center'} display={'flex'} px={1.5} py={1.35} bg={'tertiary'} borderRadius={'200px'} className='icon'><FaLinkedin /></Icon>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>


              <Flex flexWrap={'wrap'} py={4} gap={4} alignItems={'center'} justifyContent={'space-between'}>
                <Flex alignItems={'center'} justifyContent={'space-between'} gap={4}>
                  <Text as={motion.a} href="#" color={'white'}> Privacy Policy </Text>
                  <Text as={motion.a} href="#" color={'white'}> Terms of Use </Text>
                </Flex>

                <Text color={'white'}> &copy; {`${new Date().getFullYear()}`} <b>Motaa Limited.</b> All Rights Reserved.</Text>
              </Flex>
            </Box>

          </Box>
        </footer>

      </div>
    </ChakraProvider>
  );
}


const WaitlistModal = ({ onClose, show, onSubmit }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone_number, setPhone] = useState('');

  function handleSubmit(ev){
    ev.preventDefault();
    onSubmit({
      name,
      email,
      phone_number
    });

    onClose();
  }

  if (!show){
    return null
  }

  return(
    <Modal isOpen={show} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxW={'500px'} width={'90%'} mx={'auto'}>
        <ModalHeader>
          <Flex gap={3} alignItems={'center'}>
            <Stack>
              <Heading size={'sm'}>Sign up for our waiting list.</Heading>
              <Text className="smalltext" fontWeight={'200'}>Receive a notification via text, email when we launch.</Text>
            </Stack>
            <ModalCloseButton></ModalCloseButton>
          </Flex>
        </ModalHeader>

        <ModalBody>
          <form onSubmit={handleSubmit} method='post'>
            <FormControl label='Name' my={2}>
              <FormLabel> Your name </FormLabel>
              <Input value={name} onInput={e => setName(e.target.value)} name="name" placeholder='Your name' required />
            </FormControl>

            <FormControl label='Email' my={2}>
              <FormLabel> Your email address </FormLabel>
              <Input value={email} onInput={e => setEmail(e.target.value)} name="email" placeholder='Your email' type="email" required />
            </FormControl>

            <FormControl label='Phone' my={2}>
              <FormLabel> Your phone number </FormLabel>
              <Input value={phone_number} onInput={e => setPhone(e.target.value)} name="phone_number" placeholder='Your phone number' type='tel' required />
            </FormControl>
            
            <Button type='submit' my={4} w={'100%'} colorScheme='yellow' bg={'tertiary'} color={'primary'}> Notify me on Launch! </Button>
          </form>

        </ModalBody>
      </ModalContent>
    </Modal>
  )

} 



export default App;
